<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <ociNotifications :notificationData="notificationData"/>

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!--
              :src="require('@/assets/images/avatars/13-small.png')"
              badge
              class="badge-minimal"
              badge-variant="success"
           -->
          <b-avatar
            size="40"
            class="oci-navbar-profile"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="profile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ociNotifications from '@/layouts/components/ociNotifications.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ociNotifications,

    // Navbar Components
    DarkToggler,
  },
  computed: {
    ...mapGetters({ notificationData: 'app/getNotificationsList' })
  },   
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // notificationData: [
      //  {
      //    address: 'Albert Hall / Kensignton Gore, London SW7 2AP',
      //    title: 'Replace electrical wiring in Kitchen',
      //  },
      //  {
      //    address: 'London Palladium / Argyll Street, London, W1',
      //    title: 'Backup generator installation',
      //  },
      //  {
      //    address: 'Globe Theatre / 21 New Globe Walk, Bankside, London',
      //    title: 'Fix fault with emergency lighting',
      //  },
      // ],
    }
  },
  methods: {
    logout() {
      console.log('logout')
      this.$store.dispatch('app/logoutAndReset')
    },
    profile() {
      console.log('profile')
      // this.$store.dispatch('app/profile')
      this.$router.push('/profile');
    },    
  },
}
</script>
